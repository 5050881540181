import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { createContext, useEffect, useReducer } from "react";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import authReducer, { actions, initialState } from "./authReducer";

const AuthContext = createContext(null);
const AuthDispatchContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    // The promise to currentAuthenticatedUser() is rejected if there's no
    // Cognito session active, so trying this method can be used to probe
    // the current auth status.
    Auth.currentAuthenticatedUser().then(
      user => {
        dispatch({ type: actions.COGNITO_USER_SESSION_FOUND, user });
        // Indetify the Cognito user in LogRocket session tracking
        LogRocket.identify(user.attributes.sub, {
          name: user.attributes.name,
          username: user.username,
        });

        Sentry.setUser({ name: user.attributes.name, username: user.username });

        window.analytics.identify(user.attributes.sub, {
          name: user.attributes.name,
          email: user.username,
        });
      },
      () => {
        dispatch({ type: actions.COGNITO_USER_SESSION_NOT_FOUND });
      },
    );
  }, []);

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
export { AuthContext, AuthDispatchContext };
