/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "theme-ui";
import { CheckIcon, CloseIcon } from "./Icons";
import IconButton from "./IconButton";
import SVGIcon from "./SVGIcon";

export const ToastMessage = ({ appearance, children, onDismiss }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "493px",
      mt: "12px",
      padding: "8px 13px",
      lineHeight: "21px",
      bg: "content",
      border: "1px solid rgba(200, 206, 219, 0.5);",
      borderRadius: "4px",
      boxShadow: "0px 1px 26px rgba(0, 0, 0, 0.04)",
    }}
  >
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          width: "22px",
          height: "22px",
          mr: "13px",
          borderRadius: "50%",
          bg: appearance === "error" ? "error" : "accent",
        }}
      >
        <SVGIcon
          fill="white"
          icon={appearance === "success" ? <CheckIcon /> : <CloseIcon />}
          size="15px"
        />
      </div>
      <div sx={{ width: "calc(100% - 40px)" }}>{children}</div>
    </div>
    <IconButton
      icon={<CloseIcon />}
      onClick={onDismiss}
      size="15px"
      viewBox="0 2 20 20"
    />
  </div>
);

ToastMessage.propTypes = {
  appearance: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func.isRequired,
};
