import Amplify from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import AppProviders from "./AppProviders";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

LogRocket.init("ob5swl/glide");

Sentry.init({
  dsn:
    "https://cf5458b8bdaf4332a1612a5a5964173f@o471855.ingest.sentry.io/5504443",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// Add the LogRocket sessionURL to Sentry logs
LogRocket.getSessionURL(sessionURL => {
  Sentry.configureScope(scope => {
    scope.setExtra("sessionURL", sessionURL);
  });
});

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
