import React from "react";
import { ToastProvider as BaseToastProvider } from "react-toast-notifications";
import { ToastMessage } from "../../components/ToastMessage";

/**
 * Render a react-toast-notifications <ToastProvider /> with Glide's default settings.
 */
function ToastProvider(props) {
  return (
    <BaseToastProvider
      autoDismiss
      autoDismissTimeout={6000}
      components={{ Toast: ToastMessage }}
      placement="bottom-right"
      {...props}
    />
  );
}

export default ToastProvider;
