import { darken } from "polished";
import reduce from "lodash/reduce";
import startCase from "lodash/startCase";

const fontStack = "soleil, sans-serif";

const baseColors = {
  text: "#323A4A",
  textSecondary: "#798287",
  background: "#fafbfd",
  content: "#fff",
  divider: "rgba(200, 206, 219, 0.5)",
  topbar: "#E5E5E5",
  primary: "#323A4A",
  secondary: "#ff6f6f",
  muted: "#C8CEDB",
  border: "#E0E5E8",
  button: "#6E83AD",
  accent: "#009FB7",
  warn: "#ff0033",
  error: "#EB5757",
};

const HOVER_DARKEN_RATIO = 0.025;

const hoverColors = reduce(
  baseColors,
  (hoverColorsAcc, color, colorName) => ({
    ...hoverColorsAcc,
    [`hover${startCase(colorName)}`]: darken(HOVER_DARKEN_RATIO, color),
  }),
  {},
);

const buttonDefaults = {
  borderRadius: "4px",
  color: "white",
  cursor: "pointer",
  flexShrink: 0,
  fontFamily: "body",
  fontWeight: "500",
  paddingX: "12px",
  paddingY: "12px",
  ":disabled": {
    cursor: "not-allowed",
  },
};

const theme = {
  breakpoints: ["0em", "64em"],
  colors: {
    ...baseColors,
    ...hoverColors,
  },
  fonts: {
    body: fontStack,
    heading: fontStack,
  },
  fontSizes: [12, 14, 16, 18, 24, 32, 48, 64, 72],
  space: [0, 8, 16, 24, 32, 64],
  fontWeights: {
    body: 400,
    heading: 600,
  },
  radii: {
    input: "4px",
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
    },
  },
  forms: {
    input: {
      color: "text",
      bg: "white",
      border: "0.5px solid",
      borderColor: "muted",
      fontFamily: "body",
      boxSizing: "border-box",
      borderRadius: "input",
      padding: "16px",
      height: "48px",
      ":focus": {
        outline: t => `${t.colors.accent} auto 1px`,
      },
      ":disabled": {
        // bg: "rgba(0,0,0,.02)",
        cursor: "not-allowed",
        color: "textSecondary",
      },
      "::placeholder, ::-webkit-input-placeholder": {
        fontFamily: "body",
      },
      ":-internal-autofill-selected": {
        bg: "white !important",
      },
    },
    label: {
      color: "primary",
      fontSize: 1,
    },
    textArea: {
      color: "text",
      border: "0.5px solid",
      borderColor: "muted",
      fontFamily: "body",
      boxSizing: "border-box",
      borderRadius: "input",
      padding: "8px 16px",
      minHeight: "142px",
      bg: "content",
      ":focus": {
        outline: t => `${t.colors.accent} auto 1px`,
      },
      "::placeholder, ::-webkit-input-placeholder": {
        fontFamily: "body",
      },
    },
    openInput: {
      color: "text",
      border: "none",
      pl: 0,
      fontSize: 4,
      fontFamily: "body",
      lineHeight: "30px",
      borderBottom: t => `1px solid ${t.colors.accent}`,
      borderRadius: "0",
      ":focus": {
        boxShadow: t => `0 1px ${t.colors.accent}`,
        outline: "none",
      },
    },
  },
  buttons: {
    primary: {
      ...buttonDefaults,
      bg: "primary",
      ":hover": {
        bg: "hoverPrimary",
      },
    },
    secondary: {
      ...buttonDefaults,
      bg: "secondary",
      ":hover": {
        bg: "hoverSecondary",
      },
    },
    danger: {
      ...buttonDefaults,
      bg: "warn",
      ":hover": {
        bg: "hoverWarn",
      },
    },
    accent: {
      ...buttonDefaults,
      bg: "accent",
      ":hover": {
        bg: "hoverAccent",
      },
    },
    muted: {
      bg: "white",
      color: "muted",
      "&:hover": {
        color: "secondary",
      },
    },
    contentsOnly: {
      height: "auto",
      py: 0,
      bg: "transparent",
      color: "inherit",
    },
    flattenedRectangle: {
      variant: "button.rectangle",
      height: "48px",
      py: 0,
    },
  },
  flex: {
    card: {
      bg: "content",
      color: "text",
      border: "0.5px solid",
      borderColor: "divider",
      flexDirection: "column",
    },
  },
  box: {
    listItem: {
      bg: "content",
      height: "72px",
      width: "100%",
      py: 0,
      px: 0,
      m: "0 0 16px",
      border: "0.5px solid",
      borderColor: "divider",
      boxSizing: "border-box",
      boxShadow: "0px 1px 26px rgba(0, 0, 0, 0.04)",
      borderRadius: "4px",
      "&:hover": {
        border: "0.5px solid rgba(200, 206, 219, 0.2)",
        boxShadow: "0px 1px 26px rgba(0, 0, 0, 0.1)",
      },
    },
    listItemNoShadow: {
      bg: "content",
      height: "72px",
      width: "100%",
      py: 0,
      px: 0,
      m: "0",
      mb: "0",
      border: "0.5px solid",
      borderColor: "divider",
      boxSizing: "border-box",
      // boxShadow: "0px 1px 26px rgba(0, 0, 0, 0.04)",
      borderRadius: "4px",
      "&:hover": {
        // border: "0.5px solid rgba(200, 206, 219, 0.2)",
        // boxShadow: "0px 1px 26px rgba(0, 0, 0, 0.1)",
      },
    },
    listItemNoShadowTopBorderOnly: {
      bg: "content",
      height: "106px",
      width: "100%",
      py: 0,
      px: 0,
      m: "0",
      mb: "0",
      borderTop: "0.5px solid",
      borderColor: "divider",
      boxSizing: "border-box",
      borderRadius: "0 0 4px 4px",
      // boxShadow: "0px 1px 26px rgba(0, 0, 0, 0.04)",
      "&:hover": {
        // border: "0.5px solid rgba(200, 206, 219, 0.2)",
        // boxShadow: "0px 1px 26px rgba(0, 0, 0, 0.1)",
      },
    },
    listItemEmpty: {
      bg: "content",
      height: "72px",
      width: "100%",
      py: 0,
      px: 0,
      m: "0",
      border: "2px dashed",
      borderColor: "divider",
      boxSizing: "border-box",
      borderRadius: "4px",
      "&:hover": {
        border: t => `2px dashed  ${t.colors.accent}`,
      },
    },
  },
  sidebar: {
    expanded: "200px",
    collapsed: "78px",
  },
  dataSheetSidebar: {
    expanded: "271px",
    collapsed: "17px",
  },
  topNav: {
    height: "96px",
  },
};

export default theme;
