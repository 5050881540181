import PropTypes from "prop-types";
/** @jsx jsx */
import { jsx } from "theme-ui";

const SVGIcon = props => (
  <div sx={{ height: props.size, width: props.size }}>
    <svg
      height={props.size}
      sx={{
        fill: props.fill,
        display: "flex",
        alignItems: "center",
      }}
      viewBox={props.viewBox}
      width={props.size || "24px"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {props.icon}
    </svg>
  </div>
);

SVGIcon.propTypes = {
  fill: PropTypes.string,
  icon: PropTypes.element.isRequired,
  size: PropTypes.string,
  viewBox: PropTypes.string,
};

SVGIcon.defaultProps = {
  fill: "background",
  size: "24px",
  viewBox: "0 0 24 24",
};
export default SVGIcon;
