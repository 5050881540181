import { ThemeProvider } from "theme-ui";
import PropTypes from "prop-types";
import React from "react";
import AuthProvider from "./context/auth/AuthProvider";
import theme from "./theme";

const AppProviders = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>{children}</AuthProvider>
    </ThemeProvider>
  );
};

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProviders;
