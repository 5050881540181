import React, { Suspense } from "react";
import useUser from "./context/auth/useUser";
import { PROBING } from "./context/auth/authStatus";
import ToastProvider from "./context/toasts/ToastProvider";

// This split between authenticated/unauthenticated areas in the app is
// inspired by a blog post by Kent C Dodds:
// https://kentcdodds.com/blog/authentication-in-react-applications
//
// By using React.lazy(), unauthenticated users shouldn't need to download the
// entire app bundle just to see the login screen.
const AuthenticatedApp = React.lazy(() => import("./app/AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(() => import("./app/UnauthenticatedApp"));

const App = () => {
  const { authStatus, user } = useUser();

  // Prevent the login screen from momentarily flashing while auth status
  // is still unknown. This should never happen for more than a split second,
  // so a spinner isn't necessary.
  // TODO: Investigate if a spinner is actually necessary. The deployed app in the dev environment
  // shows a blank white screen for a while.
  if (authStatus === PROBING) {
    return null;
  }

  // TODO: Maybe we want a proper full-page spinner as the fallback while
  //       bundle is loading, depending on how long it takes...
  return (
    <Suspense fallback={null}>
      <ToastProvider>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </ToastProvider>
    </Suspense>
  );
};

export default App;
