import { PROBING, SIGNED_IN, SIGNED_OUT } from "./authStatus";

const initialState = {
  authStatus: PROBING,
  user: null,
  error: null,
  failedLogins: 0,
};

const actions = {
  COGNITO_SIGN_IN_SUCCEEDED: "COGNITO_SIGN_IN_SUCCEEDED",
  COGNITO_SIGN_IN_FAILED: "COGNITO_SIGN_IN_FAILED",
  COGNITO_SIGN_OUT_SUCCEEDED: "COGNITO_SIGN_OUT_SUCCEEDED",
  COGNITO_USER_SESSION_FOUND: "COGNITO_USER_SESSION_FOUND",
  COGNITO_USER_SESSION_NOT_FOUND: "COGNITO_USER_SESSION_NOT_FOUND",
};

const authReducer = (state, action) => {
  switch (action.type) {
    case actions.COGNITO_SIGN_IN_SUCCEEDED:
      return {
        authStatus: SIGNED_IN,
        user: action.user,
        failedLogins: 0,
      };
    case actions.COGNITO_SIGN_IN_FAILED:
      return {
        authStatus: SIGNED_OUT,
        error: action.error,
        failedLogins: state.failedLogins + 1,
      };
    case actions.COGNITO_SIGN_OUT_SUCCEEDED:
      return {
        authStatus: SIGNED_OUT,
        user: null,
        failedLogins: state.failedLogins,
      };
    case actions.COGNITO_USER_SESSION_FOUND:
      return {
        authStatus: SIGNED_IN,
        user: action.user,
        failedLogins: state.failedLogins,
      };
    case actions.COGNITO_USER_UPDATED:
      return {
        authStatus: SIGNED_IN,
        user: action.user,
        failedLogins: state.failedLogins,
      };
    case actions.COGNITO_USER_SESSION_NOT_FOUND:
      return {
        authStatus: SIGNED_OUT,
        failedLogins: state.failedLogins,
      };
    default:
      return state;
  }
};

export default authReducer;
export { actions, initialState };
