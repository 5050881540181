import { Auth } from "aws-amplify";
import { useCallback, useContext } from "react";
import { AuthContext, AuthDispatchContext } from "./AuthProvider";
import { actions } from "./authReducer";

const useUser = () => {
  const state = useContext(AuthContext);
  const dispatch = useContext(AuthDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error("useUser must be used within an AuthProvider");
  }

  const signIn = useCallback(
    async (username, password) => {
      try {
        const userResponse = await Auth.signIn({
          username,
          password,
          bypassCache: true,
        });

        dispatch({
          type: actions.COGNITO_SIGN_IN_SUCCEEDED,
          user: userResponse,
        });
      } catch (error) {
        dispatch({
          type: actions.COGNITO_SIGN_IN_FAILED,
          error,
        });
      }
    },
    [dispatch],
  );

  const signOut = useCallback(async () => {
    await Auth.signOut();

    dispatch({ type: "COGNITO_SIGN_OUT_SUCCEEDED" });
  }, [dispatch]);

  const updateUser = useCallback(async () => {
    const userResponse = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    dispatch({
      type: actions.COGNITO_USER_UPDATED,
      user: userResponse,
    });
  }, [dispatch]);

  return {
    ...state,
    signIn,
    signOut,
    updateUser,
  };
};

export default useUser;
