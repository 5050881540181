/* eslint-disable react/button-has-type */

/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "theme-ui";

const IconButton = ({
  disabled,
  fill,
  height,
  hoverFill,
  icon,
  size,
  viewBox,
  width,
  onClick,
  raised,
  type,
  ...props
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    sx={{
      display: "flex",
      background: raised ? "none" : "none",
      p: raised ? "3px" : 0,
      border: "none",
      borderRadius: "2px",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "&:disabled": {
        cursor: "default",
        opacity: "0.4",
      },
      "&:hover": {
        "& > svg": {
          fill: hoverFill,
        },
      },
      mx: "4px",
      width: `${size}px`,
      height: `${size}px`,
    }}
    {...props}
    // eslint-disable-next-line react/button-has-type
    type={type}
  >
    <svg
      height={height || size}
      sx={{
        fill,
        "&:hover": {
          fill: hoverFill,
        },
      }}
      viewBox={viewBox}
      width={width || size}
      xmlns="http://www.w3.org/2000/svg"
    >
      {icon}
    </svg>
  </button>
);

IconButton.propTypes = {
  disabled: PropTypes.bool,
  fill: PropTypes.string,
  height: PropTypes.string,
  hoverFill: PropTypes.string,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  raised: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  fill: "textSecondary",
  height: null,
  hoverFill: "textSecondary",
  onClick: () => {},
  raised: false,
  size: "20",
  type: "button",
  viewBox: "0 0 20 20",
  width: null,
};

export default IconButton;
